import "./Footer.scss";

import React from "react";

const Footer = () => {
  return (
    <div className="footer_container">
      <h1>Footer</h1>
      <p>placeholder</p>
      <p>placeholder</p>
    </div>
  );
};

export default Footer;
